<template>
    <div class="row">
        <div class="col-12">
            <div class="row" v-if="!isLoad">
                <div class="col-12" v-if="assignment.length">
                    <div v-for="(item, i) in assignment" :key="i" class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row mb-1">
                            <div class="col-12">
                                <router-link :to="{name:'MyCourseAssessmentTaskDiscussion', params:{idCourse: paramsId, idAssignment: item.id}}" class="float-left mb-0 text-current fw-700" :title="item.title">{{item.title}}</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <span class="font-xssss fw-700 text-grey-600 d-block" :title="item.description">{{item.description | truncate(200, '...')}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <span class="font-xssss fw-600 text-grey-500 d-block mr-1 ">Due date : </span>
                                <small class="mr-3 font-xssss">{{item.due_date | formatDate}}</small>
                            </div>
                            <div class="col-12 text-right mt-2">
                                <div class="btn cursor-default text-white mr-2" :class="{'bg-warning' : item.own_submission?.status == 0, 'bg-success' : item.own_submission?.status == 1}" v-if="item.own_submission">{{ item.own_submission?.status == 0 ? 'Not Submit' : 'Submitted' }}</div>
                                <router-link :to="{name: 'MyCourseAssessmentTaskDiscussion', params : {idCourse: paramsId, idAssignment: item.id}}" class="btn bg-primary text-white mr-2" v-if="item.own_submission && item.own_submission.score">Score : {{ item.own_submission.score }}</router-link>
                                <router-link :to="{name: 'MyCourseAssessmentTaskDiscussion', params : {idCourse: paramsId, idAssignment: item.id}}" v-if="item.own_submission == null" class="btn btn-current">See More</router-link>
                                <router-link :to="{name: 'MyCourseAssessmentTaskDiscussion', params : {idCourse: paramsId, idAssignment: item.id}}" v-if="item.own_submission && !item.own_submission.score" class="btn btn-current">See More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="!assignment.length">
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="isLoad || isStillLoad">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props:['unit', 'cycle'],
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            assignment: [],
            isStillLoad: true
        }
    },
    created(){
        this.getAssigment()
    },
    methods:{
        async getAssigment(){
            let queryLimit = 5;
            let queryOffset = 0;
            this.assignment = [];

            while(this.isStillLoad){
                await axios.get(`${process.env.VUE_APP_URL_API}/core/v2/assignment?limit=${queryLimit}&offset=${queryOffset}&slug=${this.paramsId}&type=1&cycle=${this.cycle}&unit=${this.unit}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data.length == 0){
                        this.isStillLoad = false
                        this.isLoad = false
                    }else{
                        this.assignment = [...this.assignment, ...res.data.data];
                        queryOffset = queryOffset + queryLimit
                        this.isLoad = false
                    }
                }).catch(err => {
                    console.log(err)
                    this.$swal({
                            toast: true,
                            title: 'Discussion',
                            text: 'Error fetching data',
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                })
            }
            
        }
    },
    watch:{
        unit: function() {
            this.isLoad = true
            this.getAssigment()
        }
    }
}
</script>