<template>
    <div class="row mb-3">
        <div class="col-lg-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> Assessment Tasks </h2>
                    </div>
                </div>            
            </div> 
            <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row m-0 p-0">    
                            <div class="form-group col-6 icon-input mb-0">
                                <i class="ti-reload font-xs text-grey-400"></i>
                                <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Cycle ${cycle}`" disabled>
                            </div>
                            <div class="form-group col-6 icon-input mb-0">
                                <i class="ti-search font-xs text-grey-400"></i>
                                <select v-model="unit_id" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100">
                                    <option value="">All Unit</option>
                                    <option v-for="(item, i) in syllabus.point" :key="i" :value="item.id">Cycle {{item.cycle}} - {{item.content}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isLoadMenu" class="row bg-white px-5">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="row px-2 py-2">
                        <div v-if="assessmentTaskMenu?.fft_active" class="col-sm-6">
                            <router-link :to="{name: 'MyCourseAssessmentTask', params: {idCourse: paramsId}, hash: '#discussion'}">
                                <div style="background-image: url(/images/discussion.png); background-position: center; background-size: 40%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#discussion', 'border-0': tab != '#discussion'}" class="card p-md-5 pb-5 text-left shadow rounded-lg mb-2">
                                    <div class="card-selection">
                                        <h1 class="text-grey-900 fw-700 text-center font-lg">Form-focused Task</h1>    
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="assessmentTaskMenu?.quiz_active" class="col-sm-6">
                            <router-link :to="{name: 'MyCourseAssessmentTask', params: {idCourse: paramsId}, hash: '#quiz'}">
                                <div style="background-image: url(/images/quiz.png); background-position: center; background-size: 40%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#quiz', 'border-0': tab != '#quiz'}" class="card p-md-5 pb-5 text-left shadow rounded-lg mb-2">
                                    <div class="card-selection">
                                        <h2 class="text-grey-900 fw-700 text-center font-lg">Quiz</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="assessmentTaskMenu?.individual_active" class="col-sm-6">
                            <router-link :to="{name: 'MyCourseAssessmentTask', params: {idCourse: paramsId}, hash: '#individual'}">
                                <div style="background-image: url(/images/individual.png); background-position: center; background-size: 40%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#individual', 'border-0': tab != '#individual'}" class="card p-md-5 pb-5 text-left shadow rounded-lg mb-2">
                                    <div class="card-selection">
                                        <h2 class="text-grey-900 fw-700 text-center font-lg">Individual Assignment</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="assessmentTaskMenu?.group_active" class="col-sm-6">
                            <router-link :to="{name: 'MyCourseAssessmentTask', params: {idCourse: paramsId}, hash: '#group'}">
                                <div style="background-image: url(/images/group.png); background-position: center; background-size: 40%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#group', 'border-0': tab != '#group'}" class="card p-md-5 pb-5 text-left shadow rounded-lg mb-2">
                                    <div class="card-selection">
                                        <h2 class="text-grey-900 fw-700 text-center font-lg">Group Assignment</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="assessmentTaskMenu?.achievement_active" class="col-sm-6">
                            <router-link :to="{name: 'MyCourseAssessmentTask', params: {idCourse: paramsId}, hash: '#achievement'}">
                                <div style="background-image: url(/images/achievement.png); background-position: center; background-size: 40%; background-repeat: no-repeat;" :class="{'border-warning': tab == '#achievement', 'border-0': tab != '#achievement'}" class="card p-md-5 pb-5 text-left shadow rounded-lg mb-2">
                                    <div class="card-selection">
                                        <h2 class="text-grey-900 fw-700 text-center font-lg">Achievement Test</h2>    
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center py-3" v-else>
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
            <div class="row" v-if="isShow">
                <div class="col-lg-12 p-0">
                    <div class="row" v-if="tab == '#discussion'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Discussion :unit="unit_id" :cycle="cycle"></Discussion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#quiz'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <Quiz :unit="unit_id" :cycle="cycle"></Quiz>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#individual'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <IndividualAssignment :unit="unit_id" :cycle="cycle"></IndividualAssignment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#group'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <GroupAssignment :unit="unit_id" :cycle="cycle"></GroupAssignment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="tab == '#achievement'">
                        <div class="col-lg-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-3">
                                <div class="card-body pb-0">
                                   <AchievementTest :unit="unit_id" :cycle="cycle"></AchievementTest>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</template>

<script>
import axios from 'axios';
import Quiz from './Type/Quiz.vue'
import Discussion from './Type/Discussion.vue'
import IndividualAssignment from './Type/IndividualAssignment.vue'
import GroupAssignment from './Type/GroupAssignment.vue'
import AchievementTest from './Type/AchievementTest.vue'
export default {
    name: `MyCourseAssessmentTask`,
    components:{
        Quiz,
        Discussion,
        IndividualAssignment,
        GroupAssignment,
        AchievementTest
    },
    mounted() {
        if (this.$route.hash) {
            this.isShow = true
            this.tab = this.$route.hash
        }
    },
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            syllabus: [],
            unit_id: '',
            cycles: [],
            cycle: '',
            isShow: false,
            tab: '',
            slide: {
                start: this.$route.hash == '#discussion' ? 1 : this.$route.hash == '#quiz' ? 2 : this.$route.hash == '#individual' ? 3 : this.$route.hash == '#group' ? 4 : this.$route.hash == '#achievement' ? 5 : 1,
                rewind : true,
                perPage : 2,
                gap : '2rem',
                autoplay : false,
                arrows : true,
                pagination : false,
                breakpoints: {
                    '800' : {
                        perPage: 2,
                        gap: '10rem'
                    },
                    '640' : {
                        perPage: 1,
                        gap: '4rem'
                    },
                    '480' : {
                        perPage: 1,
                        gap: '2rem'
                    },
                }
            },
            isLoadMenu: true,
            assessmentTaskMenu: null
        }
    },
    async created() {
        await this.getAssessmentTaskMenu()
        this.getCycles()
    },
    methods: {
        async getAssessmentTaskMenu(){
            this.isLoadMenu = true
            await axios.get(`${process.env.VUE_APP_URL_API}/core/course/assessment-task-menus?slug=` + this.paramsId, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.data){
                    this.assessmentTaskMenu = {
                        fft_active: res.data.data.fft_active,
                        quiz_active: res.data.data.quiz_active,
                        individual_active: res.data.data.individual_active,
                        group_active: res.data.data.group_active,
                        achievement_active: res.data.data.achievement_active
                    }
                } else {
                    this.assessmentTaskMenu = {
                        fft_active: true,
                        quiz_active: true,
                        individual_active: true,
                        group_active: true,
                        achievement_active: true
                    }
                }
            })
            console.log('ATM', this.assessmentTaskMenu)
            this.isLoadMenu = false
        },
        async getSyllabus() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/syllabus?slug=${this.paramsId}&cycle=${this.cycle}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.syllabus = res.data.data
            })
        },
        async getCycles(){
            if(localStorage.getItem(`${this.paramsId}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.paramsId}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.paramsId}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.paramsId}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.paramsId}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
            this.getSyllabus()
        },
        setCycle() {
            this.unit_id = ''
            this.getSyllabus()
        },
    },
}
</script>
<style scoped>
    .card-selection {
        height: 10px;
        cursor: pointer;
    }
    .col-sm-6 {
        padding-right: 10px;
        padding-left: 10px;
    }
</style>