import { render, staticRenderFns } from "./GroupAssignment.vue?vue&type=template&id=05386592&"
import script from "./GroupAssignment.vue?vue&type=script&lang=js&"
export * from "./GroupAssignment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports